import api from "./api";

export async function all() {
  const response = await api.get("/customers");

  return response.data;
}

export async function create(data: any) {
  const response = await api.post("/customers", data);

  return response.data;
}

export async function getById(id: number) {
  const response = await api.get(`/customers/${id}`);

  return response.data;
}

export async function update(id: number, data: any) {
  const response = await api.patch(`/customers/${id}`, data);

  return response.data;
}
