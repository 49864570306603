import DeleteIcon from "@mui/icons-material/Delete";
import {
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormLabel, Image } from "react-bootstrap";

const FileList = (props: { media: any; onDelete: any }) => {
  const [dataMedia, setDataMedia] = useState({
    created_at: "",
    isCover: false,
    isMobile: false,
    key: "",
    link: "",
    name: "",
    productMediasId: null,
    description: "",
  });

  const onDelete = () => {
    props.onDelete(dataMedia.productMediasId);
  };

  useEffect(() => {
    setDataMedia(props.media);
  }, [props]);

  return (
    <div className="row">
      <div className="col-md-5">
        <Image src={dataMedia.link} alt="upload" thumbnail fluid />
      </div>
      <div className="col-md-7 mt-sm-2">
        <form>
          <div className="row">
            <div className="col-12">
              <div className="w-100 d-flex justify-content-between">
                <FormLabel component="legend" className="m-0">
                  Mostrar como capa do produto?
                </FormLabel>
                <Tooltip title="Excluir" placement="top">
                  <IconButton aria-label="delete" onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <FormControlLabel
                control={<Switch checked={dataMedia.isCover} disabled />}
                label="Sim"
                name="isCover"
              />
            </div>
            <div className="col-12">
              <FormLabel component="legend" className="m-0">
                Mostrar somente para versão celular ?
              </FormLabel>
              <br />
              <FormControlLabel
                control={<Switch checked={dataMedia.isMobile} disabled />}
                label="Sim"
                name="isMobile"
              />
            </div>

            <div className="col-12 mt-4">
              <TextField
                className="w-100"
                label="Resumo Mídia"
                name="description"
                multiline
                rows={3}
                placeholder="Digite o resumo para esta mídia"
                value={dataMedia.description}
                disabled
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FileList;
