import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FormLabel, Image } from "react-bootstrap";

import { LinearProgressWithPercentual } from "../../../../../components/LinearProgress/LinearProgressWithPercentual";
import { addMedia } from "../../../../../services/product.service";

const UploadFileList = (props: {
  file: File;
  productId: string;
  onDelete: any;
}) => {
  const [productId, setProductId] = useState("");
  const [currentFile, setCurrentFile] = useState({});
  const [dataMedia, setDataMedia] = useState({
    description: "",
    isCover: false,
    isMobile: false,
  });
  const [previewFile, setPreviewFile] = useState("");
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [save, setSave] = useState(false);

  const handleInputChange = (event: any) => {
    const { name, value, checked } = event.target;
    setDataMedia({ ...dataMedia, [name]: checked ?? value });
  };

  const upload = (event: any) => {
    event.preventDefault();

    setProgress(0);
    setLoading(true);

    addMedia(productId, { file: currentFile, ...dataMedia }, (event) => {
      setProgress(Math.round((100 * event.loaded) / event.total));
    })
      .then(() => {
        setLoading(false);
        setSave(true);
        setMessage("Arquivo salvo com sucesso!");
      })
      .catch(() => {
        setLoading(false);
        setProgress(0);
        setMessage(
          "Não foi possível salvar o arquivo, tente novamente mais tarde!"
        );
        setCurrentFile({});
      });
  };

  useEffect(() => {
    setCurrentFile(props.file);
    setPreviewFile(URL.createObjectURL(props.file));
    setProductId(props.productId);
  }, [props]);

  return (
    <div className="row">
      <div className="col-md-5">
        <Image src={previewFile} alt="upload" thumbnail fluid />
      </div>
      <div className="col-md-7 mt-sm-2">
        <form onSubmit={upload}>
          <div className="row">
            <div className="col-12">
              <div className="w-100 d-flex justify-content-between">
                <FormLabel component="legend" className="m-0">
                  Mostrar como capa do produto?
                </FormLabel>
                <Tooltip title="Excluir" placement="top">
                  <IconButton aria-label="delete" onClick={props.onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <FormControlLabel
                control={
                  <Switch
                    checked={dataMedia.isCover}
                    onChange={handleInputChange}
                  />
                }
                label="Sim"
                name="isCover"
              />
            </div>
            <div className="col-12">
              <FormLabel component="legend" className="m-0">
                Mostrar somente para versão celular ?
              </FormLabel>
              <br />
              <FormControlLabel
                control={
                  <Switch
                    checked={dataMedia.isMobile}
                    onChange={handleInputChange}
                  />
                }
                label="Sim"
                name="isMobile"
              />
            </div>

            <div className="col-12 mt-4">
              <TextField
                variant="filled"
                className="w-100"
                label="Resumo Mídia"
                name="description"
                multiline
                rows={4}
                placeholder="Digite o resumo para esta mídia"
                value={dataMedia.description}
                onChange={handleInputChange}
              />
            </div>
            <div className="mt-4">
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="w-100"
                disabled={loading || save}
              >
                <i className="uil uil-cloud-upload" />
                {loading ? "Salvando..." : "Salvar"}
              </Button>
              {save && (
                <Alert severity="success" className="mt-2">
                  {message}
                </Alert>
              )}
              {!save && message && (
                <Alert severity="error" className="mt-2">
                  {message}
                </Alert>
              )}
              {loading && (
                <div className="mt-2">
                  <LinearProgressWithPercentual value={progress} />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UploadFileList;
