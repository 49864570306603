import api from "./api";

export async function all() {
  const response = await api.get("/employee");

  return response.data;
}

export async function create(data: any) {
  const response = await api.post("/employee", data);

  return response.data;
}

export async function getById(id: number) {
  const response = await api.get(`/employee/${id}`);

  return response.data;
}

export async function update(id: number, data: any) {
  const response = await api.patch(`/employee/${id}`, data);

  return response.data;
}
