import api from "./api";

export interface IClothe {
  clothesAttributesId?: number | null | undefined;
  model: string;
  size: string;
  dimension: string;
  genre: string;
  color: string;
}

export async function all(): Promise<IClothe[]> {
  const response = await api.get("/clothes");

  return response.data;
}

export async function create(data: IClothe) {
  const response = await api.post("/clothes", data);

  return response.data;
}

export async function getById(id: string) {
  const response = await api.get(`/clothes/${id}`);

  return response.data;
}

export async function update(id: string, data: IClothe) {
  const response = await api.patch(`/clothes/${id}`, data);

  return response.data;
}

export async function deleteById(id: string) {
  const response = await api.delete(`clothes/${id}`);

  return response.data;
}
