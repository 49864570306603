import { Divider, List, ListItem } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import UploadFileList from "./UploadFileList";
import { Container } from "./UploadMedia.style";

const UploadMedia = (props: { productId: string }) => {
  const [productId, setProductId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles((previousFile) => previousFile.concat(acceptedFiles));
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
    });

  function deleteSelectedFile(position: number) {
    setSelectedFiles(selectedFiles.filter((file, index) => index !== position));
  }

  useEffect(() => {
    setSelectedFiles([]);
    setProductId(props.productId);
  }, [props]);

  return (
    <>
      <Container
        {...getRootProps({
          isFocused,
          isDragAccept,
          isDragReject,
        })}
      >
        <input {...getInputProps()} />
        <p className="my-4">
          Arraste e solte alguns arquivos aqui ou clique para selecionar
          arquivos
        </p>
      </Container>

      {selectedFiles && (
        <div className="mt-5">
          <List
            sx={{
              width: "100%",
            }}
          >
            {selectedFiles.map((file: File, index: number) => {
              return (
                <>
                  <ListItem key={file.name}>
                    <UploadFileList
                      file={file}
                      productId={productId}
                      onDelete={() => deleteSelectedFile(index)}
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" className="my-5" />
                </>
              );
            })}
          </List>
        </div>
      )}
    </>
  );
};

export default UploadMedia;
