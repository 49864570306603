import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  CreateCategory,
  EditCategory,
  ListCategory,
} from "../pages/private/Category";
import { CreateClothes, ListClothes } from "../pages/private/Clothes";
import Dashboard from "../pages/private/Dashboard/Dashboard";
import { CreateDiscounts, ListDiscounts } from "../pages/private/Discounts";
import Order from "../pages/private/Orders/ListOrders";
import { CreatePlansTeam, ListPlansTeam } from "../pages/private/PlansTeam";
import {
  CreateProduct,
  EditProduct,
  ListProduct,
} from "../pages/private/Product";
import Stock from "../pages/private/Stock/Stock";
import { ListCustomer } from "../pages/private/User/customers";
import { CreateEmployee, ListEmployee } from "../pages/private/User/employees";
import Signin from "../pages/public/signin/Signin";
import { ProtectedRoute } from "./ProtectedRoute";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard" element={<ProtectedRoute />}>
          <Route index element={<Dashboard />} />

          <Route path="categories">
            <Route index element={<ListCategory />} />
            <Route path="new" element={<CreateCategory />} />
            <Route path=":id" element={<EditCategory />} />
          </Route>

          <Route path="products">
            <Route index element={<ListProduct />} />
            <Route path="new" element={<CreateProduct />} />
            <Route path=":id" element={<EditProduct />} />
          </Route>

          <Route path="clothes">
            <Route index element={<ListClothes />} />
            <Route path="new" element={<CreateClothes />} />
          </Route>

          <Route path="integrations">
            <Route path="plans">
              <Route index element={<ListPlansTeam />} />
              <Route path="new" element={<CreatePlansTeam />} />
            </Route>

            <Route path="discounts">
              <Route index element={<ListDiscounts />} />
              <Route path="new" element={<CreateDiscounts />} />
            </Route>
          </Route>

          <Route path="employees">
            <Route index element={<ListEmployee />} />
            <Route path="new" element={<CreateEmployee />} />
          </Route>

          <Route path="customers">
            <Route index element={<ListCustomer />} />
          </Route>

          <Route path="stocks">
            <Route index element={<Stock />} />
          </Route>

          <Route path="orders">
            <Route index element={<Order />} />
          </Route>
        </Route>

        <Route path="/signin" element={<Signin />} />
        <Route path="*" element={<Signin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
