import { useEffect } from "react";

import { Typography, Box, useTheme } from "@mui/material";

import { useThemeMode } from "../../contexts/theme.mode.context";

const Header = ({ title, subtitle }) => {
  const theme = useThemeMode();

  useEffect(() => {
    theme?.setTitle(title);
  }, []);

  return (
    <Box sx={{ mb: 2 }}>
      {subtitle && (
        <Typography variant="h6" color="primary">
          {subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default Header;
