/* eslint-disable @typescript-eslint/naming-convention */
import { createContext, useContext } from "react";

interface State {
  mode?: string;
  title?: string;
  change: () => void;
  setTitle: any;
}

export const ThemeModeContext = createContext<State | null>(null);

export const useThemeMode = () => {
  return useContext(ThemeModeContext);
};
