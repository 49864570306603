import { Person4Outlined, PersonOffOutlined } from "@mui/icons-material";
import { Box, Button, Chip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../../../components/Header/Header";
import Table from "../../../../components/Table/Table";
import { all } from "../../../../services/employees.service";
import { themeColors } from "../../../../theme";

function Employee() {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [employees, setEmployees] = useState([]);

  const columns = [
    {
      field: "userId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "username",
      headerName: "Usuário",
      flex: 0.5,
      cellClassName: "username-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "disabled",
      headerName: "Status",
      flex: 0.75,
      renderCell: ({ row: { disabled } }) => {
        return (
          <Chip
            label={disabled ? "Inativo" : "Ativo"}
            variant="outlined"
            color={disabled ? "error" : "secondary"}
          />
        );
      },
    },
  ];

  const fetchAll = () => {
    all()
      .then((employees) => {
        setEmployees(employees);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Funcionário" subtitle="Gestão de funcionários" />
        <Link to="new">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-plus-square" />}
          >
            Adicionar
          </Button>
        </Link>
      </Box>

      <Table data={employees} columns={columns} id="userId" />
    </Box>
  );
}

export default Employee;
