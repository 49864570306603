import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  useMediaQuery,
  Select as SelectMui,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Input from "../../../components/Field/Input";
import Textarea from "../../../components/Field/Textarea";
import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { all } from "../../../services/categories.service";
import { all as getAllClothes } from "../../../services/clothes.service";
import { add } from "../../../services/product.service";
import UploadMedia from "./medias/upload/UploadMedia";

export interface ICategory {
  categoryId: number;
  name: string;
  description?: string;
}

export interface IProduct {
  id?: number | null;
  name: string;
  uuid?: string;
  categoryId: number | null;
  description: string;
  sku: string;
  isPromotion: string;
  isBestSeller: string;
  isLaunch: string;
  status: string;
  price: number | null;
  clothes: number[];
  medias?: any[];
}

export interface IOption {
  value: string;
  label: string;
}

const CreateProduct: React.FC = () => {
  const initialProduct: IProduct = {
    name: "",
    categoryId: null,
    description: "",
    sku: "",
    isPromotion: "",
    isBestSeller: "",
    isLaunch: "",
    status: "Active",
    price: null,
    clothes: [],
  };

  const [productButtonElement, setProductButtonElement] = useButtonLoader(
    "Salvar e avançar",
    "Criando produto..."
  );

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [categories, setCategories] = useState(Array<ICategory>);
  const [optionClothes, setOptionClothes] = useState([] as Array<IOption>);
  const [productId, setProductId] = useState("");
  const [loading, setLoading] = useState({
    save: false,
    clothes: false,
    products: false,
  });
  const [activeStep, setActiveStep] = React.useState(0);

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const productSchema = yup.object().shape({
    name: yup.string().required("O nome da categoria é obrigatório"),
    categoryId: yup.number().required("A categoria é obrigatória"),
    description: yup.string().optional(),
    sku: yup.string().optional(),
    isPromotion: yup.string().optional(),
    isBestSeller: yup.string().optional(),
    isLaunch: yup.string().optional(),
    status: yup.string().optional(),
    price: yup.number().required("O preço é obrigatório"),
    clothes: yup.array().required("O modelo de camisa é obrigatório"),
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const findAllClothes = () => {
    setLoading({ ...loading, clothes: true });

    getAllClothes()
      .then((clothes) => {
        setLoading({ ...loading, clothes: false });
        const clothesMapped = clothes.map((clothe) => ({
          value: clothe.clothesAttributesId
            ? clothe.clothesAttributesId.toString()
            : "",
          label: `Modelo: ${clothe.model} - Tamanho: ${clothe.size} - Sexo: ${clothe.genre} - Cor: ${clothe.color}`,
        }));
        setOptionClothes(clothesMapped);
      })
      .catch((error) => {
        setLoading({ ...loading, clothes: false });
      });
  };

  const handleFormSubmit = (values, { resetForm }) => {
    setProductButtonElement(true);

    add({
      name: values.name,
      description: values.description,
      sku: values.sku,
      status: "Active",
      price: values.price.toFixed(2),
      clothes: values.clothes.map((clotheId) => Number(clotheId)),
      categoryId: Number(values.categoryId),
      isPromotion: values.isPromotion === "S",
      isBestSeller: values.isBestSeller === "S",
      isLaunch: values.isLaunch === "S",
    })
      .then((data) => {
        setProductId(data.uuid);
        setProductButtonElement(false);
        setMessage("Produto criado com sucesso");
        setTypeSnackbar("success");
        setOpenSnackbar(true);
        resetForm(initialProduct);
        handleNext();
      })
      .catch(() => {
        setProductButtonElement(false);
        setMessage("Erro ao criar um novo produto");
        setTypeSnackbar("error");
        setOpenSnackbar(true);
      });
  };

  const newProduct = () => {
    handleReset();
    setProductId("");
  };

  useEffect(() => {
    all().then((categories) => {
      setCategories(categories);
    });

    findAllClothes();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Criar Produto" subtitle="Criar um novo produto" />
        <Link to="/dashboard/products">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-arrow-left" />}
          >
            Voltar
          </Button>
        </Link>
      </Box>

      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          <Step key="product">
            <StepLabel>Informações do produto</StepLabel>
            <StepContent>
              <Formik
                initialValues={initialProduct}
                onSubmit={handleFormSubmit}
                validationSchema={productSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                        sx={{
                          "& > div": {
                            gridColumn: isNonMobile ? undefined : "span 6",
                          },
                        }}
                      >
                        <Input
                          type="text"
                          label="Título de produto"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          name="name"
                          error={!!touched.name && !!errors.name}
                          helperText={touched.name && errors.name}
                          sx={{ gridColumn: "span 3" }}
                        />
                        <FormControl
                          variant="filled"
                          sx={{ gridColumn: "span 3" }}
                        >
                          <InputLabel id="categoryId">Categoria</InputLabel>
                          <SelectMui
                            labelId="categoryId"
                            value={values.categoryId}
                            onChange={handleChange}
                            name="categoryId"
                            error={!!touched.categoryId && !!errors.categoryId}
                          >
                            <MenuItem value="" selected disabled>
                              <em>Selecione uma categoria</em>
                            </MenuItem>
                            {categories.length > 0 &&
                              categories.map((category) => (
                                <MenuItem
                                  key={category.categoryId}
                                  value={category.categoryId}
                                >
                                  {category.name}
                                </MenuItem>
                              ))}
                          </SelectMui>
                        </FormControl>

                        <Input
                          type="number"
                          label="Preço"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.price}
                          name="price"
                          error={!!touched.price && !!errors.price}
                          helperText={touched.price && errors.price}
                          sx={{ gridColumn: "span 3" }}
                        />

                        <Input
                          type="text"
                          label="SKU"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.sku}
                          name="sku"
                          error={!!touched.sku && !!errors.sku}
                          helperText={touched.sku && errors.sku}
                          sx={{ gridColumn: "span 3" }}
                        />

                        {Number(values.categoryId) === 2 && (
                          <FormControl
                            variant="filled"
                            sx={{ gridColumn: "span 6" }}
                          >
                            <InputLabel id="clothes">Modelo/Tamanho</InputLabel>
                            <SelectMui
                              id="clothes"
                              multiple
                              value={values.clothes}
                              onChange={(e) =>
                                setFieldValue("clothes", e.target.value)
                              }
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((clotheId: number) => {
                                    const clothes = optionClothes.find(
                                      (clothe) =>
                                        Number(clothe.value) ===
                                        Number(clotheId)
                                    );

                                    return (
                                      <Chip
                                        key={clotheId}
                                        label={clothes && clothes.label}
                                      />
                                    );
                                  })}
                                </Box>
                              )}
                              sx={{ mt: 2 }}
                            >
                              {optionClothes.map((clothe: IOption) => (
                                <MenuItem
                                  key={clothe.value}
                                  value={clothe.value}
                                >
                                  {clothe.label}
                                </MenuItem>
                              ))}
                            </SelectMui>
                          </FormControl>
                        )}

                        <FormControl
                          variant="filled"
                          sx={{ gridColumn: "span 2" }}
                        >
                          <InputLabel id="isPromotion">Promoção</InputLabel>
                          <SelectMui
                            labelId="isPromotion"
                            value={values.isPromotion}
                            onChange={handleChange}
                            name="isPromotion"
                            error={
                              !!touched.isPromotion && !!errors.isPromotion
                            }
                          >
                            <MenuItem value="" selected disabled>
                              <em>Selecione uma opção</em>
                            </MenuItem>
                            <MenuItem key="S" value="S">
                              Sim
                            </MenuItem>
                            <MenuItem key="N" value="N">
                              Não
                            </MenuItem>
                          </SelectMui>
                        </FormControl>

                        <FormControl
                          variant="filled"
                          sx={{ gridColumn: "span 2" }}
                        >
                          <InputLabel id="isBestSeller">
                            Mais Vendidos
                          </InputLabel>
                          <SelectMui
                            labelId="isBestSeller"
                            value={values.isBestSeller}
                            onChange={handleChange}
                            name="isBestSeller"
                            error={
                              !!touched.isBestSeller && !!errors.isBestSeller
                            }
                          >
                            <MenuItem value="" selected disabled>
                              <em>Selecione uma opção</em>
                            </MenuItem>
                            <MenuItem key="S" value="S">
                              Sim
                            </MenuItem>
                            <MenuItem key="N" value="N">
                              Não
                            </MenuItem>
                          </SelectMui>
                        </FormControl>

                        <FormControl
                          variant="filled"
                          sx={{ gridColumn: "span 2" }}
                        >
                          <InputLabel id="isLaunch">Lançamento</InputLabel>
                          <SelectMui
                            labelId="isLaunch"
                            value={values.isLaunch}
                            onChange={handleChange}
                            name="isLaunch"
                            error={!!touched.isLaunch && !!errors.isLaunch}
                          >
                            <MenuItem value="" selected disabled>
                              <em>Selecione uma opção</em>
                            </MenuItem>
                            <MenuItem key="S" value="S">
                              Sim
                            </MenuItem>
                            <MenuItem key="N" value="N">
                              Não
                            </MenuItem>
                          </SelectMui>
                        </FormControl>

                        <Textarea
                          label="Descrição"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.description}
                          name="description"
                          error={!!touched.description && !!errors.description}
                          helperText={touched.description && errors.description}
                          sx={{ gridColumn: "span 6" }}
                        />
                      </Box>
                      <Box display="flex" justifyContent="start" mt="20px">
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          sx={{ mt: 1, mr: 1 }}
                          ref={productButtonElement}
                        >
                          Salvar e avançar
                        </Button>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            </StepContent>
          </Step>
          <Step key="media">
            <StepLabel>Mídias do produto</StepLabel>
            <StepContent>
              <UploadMedia productId={productId} />
              <Button
                variant="contained"
                color="secondary"
                onClick={newProduct}
                sx={{ mt: 1, mr: 1 }}
              >
                Finalizar
              </Button>
            </StepContent>
          </Step>
        </Stepper>
      </Box>
      <SnackbarCustom
        key="productSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
};

export default CreateProduct;
