import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header/Header";
import Table from "../../../components/Table/Table";
import { all, deleteById } from "../../../services/categories.service";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";

function Category() {
  const [categories, setCategories] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [message, setMessage] = useState("");

  const fetchAll = () => {
    all()
      .then((categories) => {
        setCategories(categories);
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setMessage("Erro ao buscar categorias, tente novamente mais tarde!");
      });
  };

  const deleteCategory = (id: number) => {
    deleteById(id)
      .then((response) => {
        fetchAll();
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setMessage(
          "Não foi possível deletar esta categoria por estar sendo usada"
        );
      });
  };

  const columns = [
    {
      field: "categoryId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "name",
      headerName: "Categoria",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Ação",
      flex: 0.75,
      renderCell: ({ row: { categoryId } }) => {
        return (
          <>
            <Link
              type="button"
              className="btn-white btn btn-xs text-white"
              to={`/dashboard/categories/${categoryId}`}
            >
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                startIcon={<EditOutlined />}
              >
                Editar
              </Button>
            </Link>
            <Button
              onClick={() => deleteCategory(categoryId)}
              variant="outlined"
              size="small"
              color="error"
              startIcon={<DeleteOutlineOutlined />}
            >
              Excluir
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Categoria" subtitle="Lista" />
        <Link to="new">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-plus-square" />}
          >
            Adicionar
          </Button>
        </Link>
      </Box>

      <Table columns={columns} data={categories} id="categoryId" />
      <SnackbarCustom
        key="categorySnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
}

export default Category;
