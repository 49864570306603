import { Box, Button, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Input from "../../../components/Field/Input";
import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { create } from "../../../services/discounts.service";

const CreateDiscounts = () => {
  const initialDiscounts = {
    percentual: "",
  };

  const [discountButtonElement, setDiscountButtonElement] = useButtonLoader(
    "Criar novo desconto",
    "Criando desconto..."
  );

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const discountSchema = yup.object().shape({
    percentual: yup
      .number()
      .required("O Percentual é obrigatório")
      .min(0, "O percentual mínimo é 0")
      .max(100, "O percentual máximo é 100"),
  });

  const handleFormSubmit = (values, { resetForm }) => {
    setMessage("");
    setDiscountButtonElement(true);
    create({ percentual: Number(values.percentual) })
      .then(() => {
        setDiscountButtonElement(false);
        setOpenSnackbar(true);
        setTypeSnackbar("success");
        setMessage("Desconto criado com sucesso");
        resetForm(initialDiscounts);
      })
      .catch(() => {
        setDiscountButtonElement(false);
        setOpenSnackbar(true);
        setTypeSnackbar("error");
        setMessage("Error ao criar um novo desconto");
      });
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Criar desconto" subtitle="Adicionar um novo desconto" />
        <Link to="/dashboard/integrations/discounts">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-arrow-left" />}
          >
            Voltar
          </Button>
        </Link>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialDiscounts}
        validationSchema={discountSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Input
                type="text"
                label="Desconto"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.percentual}
                name="percentual"
                error={!!touched.percentual && !!errors.percentual}
                helperText={touched.percentual && errors.percentual}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                ref={discountButtonElement}
              >
                Criar novo desconto
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <SnackbarCustom
        key="discountSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
};

export default CreateDiscounts;
