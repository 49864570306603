import api from "./api";

export async function all() {
  const response = await api.get("/order");

  return response.data;
}

export async function getById(id: number) {
  const response = await api.get(`/order/${id}`);

  return response.data;
}
