import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  useMediaQuery,
  Select as SelectMui,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Input from "../../../components/Field/Input";
import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { create } from "../../../services/clothes.service";

export interface IClothes {
  clothesAttributesId?: number | null;
  model: string;
  size: string;
  dimension: string;
  genre: string;
  color: string;
}

export interface IOption {
  value: string;
  label: string;
}

const CreateClothes = () => {
  const initialClothes: IClothes = {
    model: "",
    size: "",
    dimension: "",
    genre: "",
    color: "",
  };

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const clothesSchema = yup.object().shape({
    model: yup.string().required("O modelo da camisa é obrigatório"),
    size: yup.string().required("O tamanho é obrigatório"),
    dimension: yup.string().required("A dimensão é obrigatória"),
    genre: yup.string().required("O gênero é obrigatório"),
    color: yup.string().required("A cor é obrigatória"),
  });

  const [clothesButtonElement, setClothesButtonElement] = useButtonLoader(
    "Criar nova camisa",
    "Criando camisa..."
  );

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleFormSubmit = (values, { resetForm }) => {
    create(values)
      .then(() => {
        setMessage("Camisa criada com sucesso");
        setClothesButtonElement(false);
        setTypeSnackbar("success");
        setOpenSnackbar(true);
        resetForm(initialClothes);
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setClothesButtonElement(false);

        if (error.response.status === 400) {
          setTypeSnackbar("warning");
          setMessage("Esta camisa já está cadastrada");
          return;
        }
        setTypeSnackbar("error");
        setMessage("Error ao criar uma nova camisa");
      });
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Modelo Camisa" subtitle="Criar novo modelo de camisa" />
        <Link to="/dashboard/clothes">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-arrow-left" />}
          >
            Voltar
          </Button>
        </Link>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialClothes}
        validationSchema={clothesSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel id="model">Modelo</InputLabel>
                <SelectMui
                  labelId="model"
                  value={values.model}
                  onChange={handleChange}
                  name="model"
                  error={!!touched.model && !!errors.model}
                >
                  <MenuItem value="" selected disabled>
                    <em>Selecione uma opção</em>
                  </MenuItem>
                  <MenuItem key="adult" value="adulto">
                    Adulto
                  </MenuItem>
                  <MenuItem key="children" value="infantil">
                    Infantil
                  </MenuItem>
                </SelectMui>
              </FormControl>

              {values.model !== "infantil" && (
                <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                  <InputLabel id="size">Tamanho</InputLabel>
                  <SelectMui
                    labelId="size"
                    value={values.size}
                    onChange={handleChange}
                    name="size"
                    error={!!touched.size && !!errors.size}
                  >
                    <MenuItem value="" selected disabled>
                      <em>Selecione uma opção</em>
                    </MenuItem>
                    <MenuItem key="pp" value="PP">
                      PP
                    </MenuItem>
                    <MenuItem key="p" value="P">
                      P
                    </MenuItem>
                    <MenuItem key="m" value="M">
                      M
                    </MenuItem>
                    <MenuItem key="g" value="G">
                      G
                    </MenuItem>
                    <MenuItem key="gg" value="GG">
                      GG
                    </MenuItem>
                    <MenuItem key="xg" value="XG">
                      XG
                    </MenuItem>
                    <MenuItem key="xgg" value="XGG">
                      XGG
                    </MenuItem>
                    <MenuItem key="eg" value="EG">
                      EG
                    </MenuItem>
                    <MenuItem key="egg" value="EGG">
                      EGG
                    </MenuItem>
                  </SelectMui>
                </FormControl>
              )}

              {values.model === "infantil" && (
                <Input
                  type="text"
                  label="Tamanho"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.size}
                  name="size"
                  error={!!touched.size && !!errors.size}
                  helperText={touched.size && errors.size}
                  sx={{ gridColumn: "span 2" }}
                />
              )}

              <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel id="genre">Gênero</InputLabel>
                <SelectMui
                  labelId="genre"
                  value={values.genre}
                  onChange={handleChange}
                  name="genre"
                  error={!!touched.genre && !!errors.genre}
                >
                  <MenuItem value="" selected disabled>
                    <em>Selecione uma opção</em>
                  </MenuItem>
                  <MenuItem key="male" value="M">
                    Masculino
                  </MenuItem>
                  <MenuItem key="female" value="F">
                    Feminino
                  </MenuItem>
                  <MenuItem key="unissex" value="U">
                    Unissexo
                  </MenuItem>
                </SelectMui>
              </FormControl>

              <Input
                type="text"
                label="Cor"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.color}
                name="color"
                error={!!touched.color && !!errors.color}
                helperText={touched.color && errors.color}
                sx={{ gridColumn: "span 2" }}
              />

              <Input
                type="text"
                label="Dimensão"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.dimension}
                name="dimension"
                error={!!touched.dimension && !!errors.dimension}
                helperText={touched.dimension && errors.dimension}
                sx={{ gridColumn: "span 4" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                ref={clothesButtonElement}
              >
                Criar novo modelo
              </Button>
            </Box>
          </form>
        )}
      </Formik>

      <SnackbarCustom
        key="clothesSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
};

export default CreateClothes;
