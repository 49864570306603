import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Input from "../../../../components/Field/Input";
import Header from "../../../../components/Header/Header";
import SnackbarCustom from "../../../../components/Snackbar/Snackbar";
import useButtonLoader from "../../../../hooks/useButtonLoader";
import { create } from "../../../../services/employees.service";

const CreateEmployee = () => {
  const initialEmployee = {
    username: "",
    email: "",
    changePassword: "N",
    confirmPassword: "",
    password: "",
    userTypeId: 2,
  };

  const [employeeButtonElement, setEmployeeButtonElement] = useButtonLoader(
    "Criar novo funcionário",
    "Criando funcionário..."
  );

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d@$&+,:;=?@#|'<>.^*()%!-]{8,}$/;

  const employeeSchema = yup.object().shape({
    username: yup.string().required("O usuário é obrigatório"),
    email: yup
      .string()
      .email("O email deve ser válido")
      .required("O email é obrigatório"),
    changePassword: yup.string().notRequired(),
    password: yup
      .string()
      .matches(
        passwordRegex,
        "A senha deve conter caracteres epeciais, letras minúscula e maiúscula"
      )
      .required("A senha é obrigatória"),
    confirmPassword: yup
      .string()
      .matches(
        passwordRegex,
        "A senha deve conter caracteres epeciais, letras minúscula e maiúscula"
      )
      .required("Confirmar senha é obrigatória"),
    userTypeId: yup.number().required("O tipo do usuário é obrigatório"),
  });

  const handleFormSubmit = (values, { resetForm }) => {
    setMessage("");
    setEmployeeButtonElement(true);

    create({
      user: {
        email: values.email,
        password: values.password,
        username: values.username,
        changePassword: values.changePassword === "S",
        userTypeId: Number(values.userTypeId),
      },
    })
      .then(() => {
        setMessage("Funcionário criado com sucesso");
        setOpenSnackbar(true);
        setTypeSnackbar("success");
        setEmployeeButtonElement(false);
        resetForm(initialEmployee);
      })
      .catch(() => {
        setMessage("Error ao cadastrar um funcionário");
        setEmployeeButtonElement(false);
        setTypeSnackbar("error");
        setOpenSnackbar(true);
      });
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header
          title="Criar Funcionário"
          subtitle="Criar um novo usuário para o sistema"
        />
        <Link to="/dashboard/employees">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-arrow-left" />}
          >
            Voltar
          </Button>
        </Link>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialEmployee}
        validationSchema={employeeSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Input
                type="text"
                label="Usuário"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                name="username"
                error={!!touched.username && !!errors.username}
                helperText={touched.username && errors.username}
                sx={{ gridColumn: "span 2" }}
              />
              <Input
                type="text"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={!!touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl variant="filled" sx={{ gridColumn: "span 4" }}>
                <InputLabel id="userTypeId">Tipo de Acesso</InputLabel>
                <Select
                  labelId="userTypeId"
                  value={values.userTypeId}
                  onChange={handleChange}
                  name="userTypeId"
                  error={!!touched.userTypeId && !!errors.userTypeId}
                  disabled
                >
                  <MenuItem value="2" key="admin">
                    Administrador
                  </MenuItem>
                  <MenuItem value="1" key="customer">
                    Cliente
                  </MenuItem>
                </Select>
              </FormControl>
              <Input
                type="password"
                label="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={!!touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: "span 2" }}
              />
              <Input
                type="password"
                label="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.confirmPassword}
                name="confirmPassword"
                error={!!touched.confirmPassword && !!errors.confirmPassword}
                helperText={touched.confirmPassword && errors.confirmPassword}
                sx={{ gridColumn: "span 2" }}
              />
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                key="buttonSaveEmployee"
                ref={employeeButtonElement}
              >
                Criar novo usuário
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <SnackbarCustom
        key="employeeSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
};

export default CreateEmployee;
