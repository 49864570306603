import api from "./api";

export async function all() {
  const response = await api.get("/product");

  return response.data;
}

export async function add(data: any) {
  const response = await api.post("/product", data);

  return response.data;
}

export async function getById(id: string) {
  const response = await api.get(`/product/${id}`);

  return response.data;
}

export async function update(id: string, data: any) {
  // eslint-disable-next-line no-param-reassign
  if (data.clothes.lenght === 0) delete data.clothes;

  const response = await api.put(`/product/${id}`, data);

  return response.data;
}

export async function deleteById(uuid: string) {
  const response = await api.delete(`/product/${uuid}`);

  return response.data;
}

export async function addMedia(
  productId: string,
  data: any,
  onUploadProgress: any
) {
  const formData = new FormData();

  formData.append("description", data.description);
  formData.append("media", data.file);

  if ("isMobile" in data) {
    formData.append("isMobile", data.isMobile ? "S" : "N");
  }

  if ("isCover" in data) {
    formData.append("isCover", data.isCover ? "S" : "N");
  }

  return api.post(`product/${productId}/medias`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

export async function deleteMediaById(id: number) {
  const response = await api.delete(`/product/medias/${id}`);

  return response.data;
}
