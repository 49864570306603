import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as yup from "yup";

import Input from "../../../components/Field/Input";
import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { all } from "../../../services/discounts.service";
import { create } from "../../../services/plansTeams.service";

const CreatePlansTeam = () => {
  const initialPlansTeam = {
    planId: Date.now().toString(),
    planName: "",
    discountId: "",
  };

  const [discounts, setDiscounts] = useState([]);

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const [planTeamButtonElement, setPlanTeamButtonElement] = useButtonLoader(
    "Criar novo plano",
    "Criando plano..."
  );

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const plansTeamSchema = yup.object().shape({
    planName: yup.string().required("O nome do plano é obrigatório"),
    discountId: yup.number().required("O desconto é obrigatório"),
  });

  const fetchAllDiscounts = () => {
    all()
      .then((categories) => {
        setDiscounts(categories);
      })
      .catch(() => {
        setMessage("Error ao carregar os descontos");
      });
  };

  const handleFormSubmit = (values, { resetForm }) => {
    setPlanTeamButtonElement(true);
    create(values)
      .then(() => {
        setMessage("Plano criado com sucesso");
        setPlanTeamButtonElement(false);
        setTypeSnackbar("success");
        setOpenSnackbar(true);
        resetForm(initialPlansTeam);
      })
      .catch(() => {
        setMessage("Error ao criar um novo plano");
        setTypeSnackbar("error");
        setOpenSnackbar(true);
        setPlanTeamButtonElement(true);
      });
  };

  useEffect(() => {
    fetchAllDiscounts();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header
          title="Criar Plano Clube"
          subtitle="Criar novo plano interno do clube"
        />
        <Link to="/dashboard/integrations/plans">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-arrow-left" />}
          >
            Voltar
          </Button>
        </Link>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialPlansTeam}
        validationSchema={plansTeamSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              <Input
                type="text"
                label="Plano"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.planName}
                name="planName"
                error={!!touched.planName && !!errors.planName}
                helperText={touched.planName && errors.planName}
                sx={{ gridColumn: "span 2" }}
              />
              <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                <InputLabel id="discountId">Desconto (%)</InputLabel>
                <Select
                  labelId="discountId"
                  value={values.discountId}
                  onChange={handleChange}
                  name="discountId"
                  error={!!touched.discountId && !!errors.discountId}
                >
                  <MenuItem value="" selected disabled>
                    Selecione uma opção
                  </MenuItem>
                  {discounts.length > 0 &&
                    discounts.map((discount: any) => (
                      <MenuItem
                        key={discount.discountId}
                        value={Number(discount.discountId)}
                      >
                        {discount.percentual}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                ref={planTeamButtonElement}
              >
                Criar novo plano
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      <SnackbarCustom
        key="plansTeamSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
};

export default CreatePlansTeam;
