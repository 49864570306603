import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import Header from "../../../components/Header/Header";
import Table from "../../../components/Table/Table";
import { all } from "../../../services/orders.service";

function Order() {
  const [orders, setOrders] = useState([]);

  const fetchAll = () => {
    all()
      .then((orders) => {
        setOrders(orders);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      field: "orderId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "nameReceiver",
      headerName: "Entrega p/",
      flex: 0.5,
    },
    {
      field: "city",
      headerName: "Cidade",
      flex: 0.5,
    },
    {
      field: "state",
      headerName: "Estado",
      flex: 0.5,
    },
    {
      field: "totalAmount",
      headerName: "Total R$",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
    },
    {
      field: "createdAt",
      headerName: "Data",
      flex: 0.5,
    },
  ];

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Pedido" subtitle="Listar" />
      </Box>

      <Table columns={columns} data={orders} id="orderId" />
    </Box>
  );
}

export default Order;
