import { useRef, useState, useEffect } from "react";

const useButtonLoader = (defaultText = "Load", loadingText = "Loading...") => {
  const [isLoading, setLoading] = useState(false);
  const element: any = useRef(null);

  useEffect(() => {
    if (element.current) {
      if (isLoading) {
        element.current.disabled = true;
        element.current.innerHTML = loadingText;
      } else {
        element.current.disabled = false;
        element.current.innerHTML = defaultText;
      }
    }
  }, [isLoading]);

  return [element, setLoading];
};

export default useButtonLoader;
