/* eslint-disable @typescript-eslint/naming-convention */
import {
  Box,
  CssBaseline,
  styled,
  Drawer,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Avatar,
  Menu,
  MenuItem,
  Collapse,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useOutlet } from "react-router-dom";

import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { useThemeMode } from "../contexts/theme.mode.context";
import useAuth from "../hooks/useAuth.hook";

import { AuthContext } from "../contexts/auth.context";
import Navbar from "../pages/global/Navbar/Navbar";
import Sidebar from "../pages/global/Sidebar/Sidebar";
import { SidebarData } from "../pages/global/Sidebar/SidebarData";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const ProtectedRoute = () => {
  const navigate = useNavigate();
  const { Logout, user } = useAuth();
  const { signed } = useContext(AuthContext);
  const outlet = useOutlet();
  const themeMode = useThemeMode();
  const [open, setOpen] = useState(true);
  const [subNav, setSubNav] = useState("");
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!signed) {
    return <Navigate to="/signin" replace />;
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, fontWeight: 700 }}
            color="white"
          >
            {themeMode?.title}
          </Typography>
          <IconButton onClick={themeMode?.change}>
            {themeMode?.mode === "dark" ? (
              <DarkModeOutlinedIcon />
            ) : (
              <LightModeOutlinedIcon />
            )}
          </IconButton>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={user && user.username}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  alt={user && user.username}
                  src="/assets/imgs/avatars/endtoend.jpeg"
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={Logout}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Box sx={{ flexGrow: 1, pl: 2 }}>
            <img
              src="/assets/imgs/bumerangue.png"
              width={10}
              alt="End to End"
            />
          </Box>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List component="nav">
          {SidebarData.map((item, index) => {
            if (item.disabled) return null;

            return (
              <>
                <ListItemButton
                  onClick={() => {
                    if (item.subMenu) {
                      setSubNav(item.path === subNav ? "" : item.path);
                    } else {
                      navigate(item.path);
                    }
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                  {item.subMenu &&
                    (item.path === subNav ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                {item.subMenu && (
                  <Collapse
                    in={item.path === subNav}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {item.subMenu.map((item, index) => {
                        if (item.disabled) return null;
                        return (
                          <ListItemButton
                            key={`sub-${index}`}
                            sx={{ pl: 4 }}
                            onClick={() => {
                              navigate(item.path);
                            }}
                          >
                            <ListItemText primary={item.title} />
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                )}
              </>
            );
          })}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {outlet}
      </Main>
    </Box>
  );

  return (
    <>
      <CssBaseline />
      <div className="app">
        <Sidebar />
        <main className="content">
          <Navbar />
          <Box m="20px">{outlet}</Box>
        </main>
      </div>
    </>
  );
};
