import { AuthProvider } from "./contexts/auth.context";
import ThemesProvider from "./providers/ThemeProvider";
import AppRouter from "./routes";

const App = () => {
  return (
    <AuthProvider>
      <ThemesProvider>
        <AppRouter />
      </ThemesProvider>
    </AuthProvider>
  );
};

export default App;
