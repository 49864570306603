import api from "./api";

export async function all() {
  const response = await api.get("/integration/funclub/planTeam");

  return response.data;
}

export async function create(data: {
  planId: string;
  planName: string;
  discountId: number;
}) {
  const response = await api.post("/integration/funclub/planTeam", {
    ...data,
    discountId: Number(data.discountId),
  });

  return response.data;
}

export async function getById(id: number) {
  const response = await api.get(`/integration/funclub/planTeam/${id}`);

  return response.data;
}

export async function update(id: number, data: any) {
  const response = await api.patch(`/integration/funclub/planTeam/${id}`, data);

  return response.data;
}

export async function deleteById(id: number) {
  const response = await api.delete(`/integration/funclub/planTeam/${id}`);

  return response.data;
}
