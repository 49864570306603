import { Box, Button, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";

import Input from "../../../components/Field/Input";
import Textarea from "../../../components/Field/Textarea";
import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { getById, update } from "../../../services/categories.service";

const EditCategory = () => {
  const { id } = useParams();

  const initialCategory = {
    name: "",
    description: "",
  };

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const categorySchema = yup.object().shape({
    name: yup.string().required("O nome da categoria é obrigatório"),
    description: yup.string().optional(),
  });

  const [categoryEditButtonElement, setCategoryEditButtonElement] =
    useButtonLoader("Atualizar categoria", "Atualizando categoria...");

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleFormSubmit = (values: any) => {
    update(Number(id), {
      name: values.name,
      description: values.description,
    })
      .then(() => {
        setMessage("Categoria atualizada com sucesso!");
        setCategoryEditButtonElement(false);
        setTypeSnackbar("success");
        setOpenSnackbar(true);
      })
      .catch(() => {
        setMessage("Error ao atualizar a categoria");
        setTypeSnackbar("error");
        setOpenSnackbar(true);
        setCategoryEditButtonElement(true);
      });
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Editar Categoria" subtitle="Edição da categoria" />
        <Link to="/dashboard/categories">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-arrow-left" />}
          >
            Voltar
          </Button>
        </Link>
      </Box>

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialCategory}
        validationSchema={categorySchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleChange,
          handleSubmit,
        }) => {
          useEffect(() => {
            getById(Number(id))
              .then((response) => {
                if (response) {
                  setFieldValue("name", response.name, false);
                  setFieldValue("description", response.description, false);
                }
              })
              .catch((error) => {
                console.error(error);
              });
          }, []);

          return (
            <form onSubmit={handleSubmit}>
              <Box
                display="grid"
                gap="30px"
                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                sx={{
                  "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                }}
              >
                <Input
                  type="text"
                  label="Nome"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={!!touched.name && !!errors.name}
                  helperText={touched.name && errors.name}
                  sx={{ gridColumn: "span 4" }}
                />
                <Textarea
                  label="Descrição"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  name="description"
                  error={!!touched.description && !!errors.description}
                  helperText={touched.description && errors.description}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  ref={categoryEditButtonElement}
                >
                  Atualizar categoria
                </Button>
              </Box>
            </form>
          );
        }}
      </Formik>

      <SnackbarCustom
        key="categoryEditSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
};

export default EditCategory;
