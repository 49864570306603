import { Box, useTheme } from "@mui/material";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

const Table = ({ data, columns, id }) => {
  const [dataTable, setDataTable] = useState([]);
  const [columnsTable, setColumnsTable] = useState([]);

  useEffect(() => {
    setDataTable(data);
    setColumnsTable(columns);
  }, [data, columns]);

  return (
    <Box m="10px 0 0 0" height="75vh">
      {dataTable && (
        <DataGrid
          rows={dataTable}
          columns={columnsTable}
          getRowId={(row: any) => row[id]}
          components={{ Toolbar: GridToolbar }}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      )}
    </Box>
  );
};

export default Table;
