import { DeleteOutlineOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import { all, deleteById, IClothe } from "../../../services/clothes.service";

function ListClothes() {
  const [clothes, setClothes] = useState([] as unknown as IClothe[]);

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const fetchAll = () => {
    all()
      .then((clothes) => {
        setClothes(clothes);
      })
      .catch(() => {
        setOpenSnackbar(true);
        setTypeSnackbar("error");
        setMessage("Não foi possível obter as camisas");
      });
  };

  const deleteClothes = (id: string) => {
    deleteById(id)
      .then(() => {
        setOpenSnackbar(true);
        setTypeSnackbar("success");
        setMessage("Deletado com sucesso");
        fetchAll();
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setTypeSnackbar("error");

        if (error.response.status === 400) {
          setMessage("Não foi possível deletar esta camisa porque está em uso");

          return;
        }

        setMessage(
          "Não foi possíve deletar este camisa, tente novamente mais tarde!"
        );
      });
  };

  const columns = [
    {
      field: "clothesAttributesId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "model",
      headerName: "Modelo",
      flex: 0.5,
    },
    {
      field: "size",
      headerName: "Tamanho",
      flex: 0.5,
    },
    {
      field: "genre",
      headerName: "Gênero",
      flex: 0.5,
    },
    {
      field: "color",
      headerName: "Cor",
      flex: 0.5,
    },
    {
      field: "action",
      headerName: "Ação",
      flex: 0.75,
      renderCell: ({ row: { clothesAttributesId } }) => {
        return (
          <>
            <Button
              onClick={() => deleteClothes(clothesAttributesId)}
              variant="outlined"
              size="small"
              color="error"
              startIcon={<DeleteOutlineOutlined />}
            >
              Excluir
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Header title="Camisa" subtitle="Gerenciamento de camisa" />
          <Link to="new">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              startIcon={<i className="uil uil-plus-square" />}
            >
              Adicionar
            </Button>
          </Link>
        </Box>

        <Table data={clothes} id="clothesAttributesId" columns={columns} />
      </Box>
      <SnackbarCustom
        key="clothesListSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </>
  );
}

export default ListClothes;
