import React, { createContext, useEffect, useState } from "react";

import api from "../services/api";

interface IAuthContextData {
  signed: boolean;
  user: any;
  Signin(username: string, password: string): Promise<void>;
  Logout(): void;
}

export const AuthContext = createContext<IAuthContextData>(
  {} as IAuthContextData
);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState<any>(null);

  const Signin = async (username: string, password: string) => {
    const response = await api.post("/auth/signin", {
      username,
      password,
    });

    if (response.data.error) {
      console.error(response.data.error);
      return;
    }

    setUser(response.data.user);
    api.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
    sessionStorage.setItem("@App:token", response.data.accessToken);
    sessionStorage.setItem("@App:user", JSON.stringify(response.data.user));
  };

  const Logout = () => {
    sessionStorage.clear();
    setUser(null);
  };

  useEffect(() => {
    const storagedUser = sessionStorage.getItem("@App:user");
    const storagedToken = sessionStorage.getItem("@App:token");

    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      api.defaults.headers.Authorization = `Bearer ${storagedToken}`;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        Signin,
        Logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
