import { DeleteOutlineOutlined, EditOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header/Header";
import Table from "../../../components/Table/Table";
import { all, deleteById } from "../../../services/product.service";

function ListProduct() {
  const [products, setProducts] = useState([]);

  const fetchAll = () => {
    all().then((products) => {
      setProducts(products);
    });
  };

  const deleteProduct = (id: string) => {
    deleteById(id)
      .then((response) => {
        fetchAll();
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const columns = [
    {
      field: "productId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "name",
      headerName: "Produto",
      flex: 0.5,
    },
    {
      field: "price",
      headerName: "Preço (R$)",
      flex: 0.5,
    },
    {
      field: "description",
      headerName: "Descrição",
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 0.5,
      renderCell: ({ row: { status } }) => {
        return (
          <span className="badge rounded-pill text-bg-success">{status}</span>
        );
      },
    },
    {
      field: "action",
      headerName: "Ação",
      flex: 0.75,
      renderCell: ({ row: { uuid } }) => {
        return (
          <>
            <Link
              type="button"
              className="btn-white btn btn-xs text-white"
              to={uuid}
            >
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                startIcon={<EditOutlined />}
              >
                Editar
              </Button>
            </Link>
            <Button
              onClick={() => deleteProduct(uuid)}
              variant="outlined"
              size="small"
              color="error"
              startIcon={<DeleteOutlineOutlined />}
            >
              Excluir
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Produto" subtitle="Listar" />
        <Link to="new">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-plus-square" />}
          >
            Adicionar
          </Button>
        </Link>
      </Box>

      <Table columns={columns} data={products} id="productId" />
    </Box>
  );
}

export default ListProduct;
