import { TextField } from "@mui/material";

const Textarea = ({
  label,
  onBlur,
  onChange,
  value,
  name,
  error,
  helperText,
  sx,
}) => {
  return (
    <TextField
      variant="filled"
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      name={name}
      error={error}
      helperText={helperText}
      sx={sx}
      minRows={4}
      multiline
    />
  );
};

export default Textarea;
