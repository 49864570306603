import { Formik } from "formik";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import * as yup from "yup";

import { Button } from "@mui/material";

import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import useAuth from "../../../hooks/useAuth.hook";
import useButtonLoader from "../../../hooks/useButtonLoader";

import "./Signin.scss";

function Signin() {
  const authContext = useAuth();

  const initialSignin = {
    username: "",
    password: "",
  };

  const [signinButtonElement, setSigninButtonElement] = useButtonLoader(
    "Entrar",
    "Entrando..."
  );
  const [showPassword, setShowPassword] = useState(false);
  const [openSnackbar, setOpenSnabackbar] = useState(false);

  const signinSchema = yup.object().shape({
    username: yup.string().required("O usuário é obrigatório"),
    password: yup
      .string()
      .required("A senha é obrigatória")
      .min(6, "A senha deve ter no mínimo 6 caracteres"),
  });

  function handleShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleSignin = async (values: any) => {
    setSigninButtonElement(true);
    authContext.Signin(values.username, values.password).catch(() => {
      setSigninButtonElement(false);
      setOpenSnabackbar(true);
    });
  };

  if (authContext.signed) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="signin">
      <div className="container d-flex justify-content-center vh-100 align-items-center">
        <div className="signin-wrapper p-5">
          <Formik
            onSubmit={handleSignin}
            validationSchema={signinSchema}
            initialValues={initialSignin}
          >
            {({ values, handleBlur, handleChange, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <h1 className="signin-title mb-5">
                  <img
                    src="assets/imgs/logo-preferencial.png"
                    alt="Logo end to end"
                    width={200}
                  />
                </h1>
                <div className="input-custom-field mb-4">
                  <input
                    type="text"
                    placeholder="Insira seu usuário"
                    aria-label="Username"
                    aria-describedby="username"
                    id="username"
                    value={values.username}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <i className="icon uil uil-user" />
                </div>
                <div className="input-custom-field mb-4">
                  <input
                    type={!showPassword ? "password" : "text"}
                    placeholder="Insira sua senha"
                    aria-label="Password"
                    aria-describedby="password"
                    id="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <i className="icon uil uil-lock" />
                  <i
                    className={`uil showHidePw ${
                      showPassword ? "uil-eye" : "uil-eye-slash"
                    }`}
                    onClick={handleShowPassword}
                    onKeyDown={handleShowPassword}
                  />
                </div>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  size="large"
                  ref={signinButtonElement}
                  color="primary"
                >
                  Entrar
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <SnackbarCustom
        key="signinSnackbar"
        message="Credenciais inválidas"
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnabackbar(false)}
        severity="error"
      />
    </section>
  );
}

export default Signin;
