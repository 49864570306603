import { Person4Outlined, PersonOffOutlined } from "@mui/icons-material";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import Header from "../../../../components/Header/Header";
import Table from "../../../../components/Table/Table";
import { all } from "../../../../services/customers.service";
import { themeColors } from "../../../../theme";

function Employee() {
  const theme = useTheme();
  const colors = themeColors(theme.palette.mode);
  const [customers, setCustomers] = useState([]);

  const columns = [
    {
      field: "userId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "username",
      headerName: "Usuário",
      flex: 0.5,
      cellClassName: "username-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "disabled",
      headerName: "Status",
      flex: 0.75,
      renderCell: ({ row: { disabled } }) => {
        return (
          <Chip
            label={disabled ? "Inativo" : "Ativo"}
            variant="outlined"
            color={disabled ? "error" : "secondary"}
          />
        );
      },
    },
  ];

  const fetchAll = () => {
    all()
      .then((customers) => {
        setCustomers(customers);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header
          title="Cliente/Sócio"
          subtitle="Gerenciamento de clientes/sócios"
        />
      </Box>

      <Table columns={columns} data={customers} id="userId" />
    </Box>
  );
}

export default Employee;
