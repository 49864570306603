import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

const SnackbarCustom = ({
  key,
  severity,
  message,
  openSnackbar,
  handleClose,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openSnackbar);
  }, [openSnackbar]);

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      key={key}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default SnackbarCustom;
