import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { useProSidebar } from "react-pro-sidebar";

import { ColorModeContext, themeColors } from "../../../theme";

function Navbar() {
  const theme = useTheme();
  const { toggleSidebar } = useProSidebar();
  const colors = themeColors(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Box
        display="flex"
        borderRadius="3px"
        style={{ backgroundColor: colors.primary[400] }}
      >
        <IconButton
          onClick={() => toggleSidebar()}
          className="d-block d-sm-none"
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/*
        // TODO: implementation user account details
        <IconButton>
          <PersonOutlinedIcon />
        </IconButton> */}
      </Box>
    </Box>
  );
}

export default Navbar;
