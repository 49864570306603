import { TextField } from "@mui/material";

const Input = ({
  type,
  label,
  onBlur,
  onChange,
  value,
  name,
  error,
  helperText,
  sx,
}) => {
  return (
    <TextField
      key={name}
      fullWidth
      type={type}
      label={label}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      name={name}
      error={error}
      helperText={helperText}
      sx={sx}
    />
  );
};

export default Input;
