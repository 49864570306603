import { Alert, ListItem, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

import { deleteMediaById } from "../../../../../services/product.service";
import UploadMedia from "../upload/UploadMedia";
import FileList from "./FileList";

const EditMedia = (props: { medias: any; productId: string }) => {
  const DURATION_SNACKBAR_IN_MS = 3000;
  const [medias, setMedias] = useState([] as any);
  const [productId, setProductId] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  const onDelete = (mediaId: number) => {
    setMedias(medias.filter((media) => media.productMediasId !== mediaId));

    deleteMediaById(mediaId)
      .then(() => {
        setOpenSnackbar(true);
        setMessage("Mídia deletada com sucesso!");
      })
      .catch(() => {
        setOpenSnackbar(true);
        setMessage("Error ao deletar a mídia, tente novamente!");
      });
  };

  useEffect(() => {
    setMedias(props.medias);
    setProductId(props.productId);
  }, [props]);

  return (
    <>
      <div className="mt-5">
        {productId && <UploadMedia productId={productId} />}
        {medias &&
          medias?.map((media) => (
            <ListItem key={media.key}>
              <FileList
                media={media}
                onDelete={(mediaId) => onDelete(mediaId)}
              />
              <hr className="my-5 mx-4" />
            </ListItem>
          ))}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={DURATION_SNACKBAR_IN_MS}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditMedia;
