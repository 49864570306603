import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header/Header";
import Table from "../../../components/Table/Table";
import { all } from "../../../services/plansTeams.service";

function PlansTeam() {
  const [plansTeam, setPlansTeam] = useState([]);

  const columns = [
    {
      field: "planTeamId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "planId",
      headerName: "ID Plano clube",
      flex: 0.25,
    },
    {
      field: "planName",
      headerName: "Plano",
      flex: 0.25,
    },
    {
      field: "percentual",
      headerName: "Percentual",
      flex: 0.5,
      renderCell: ({ row: { discount } }) => {
        return discount.percentual;
      },
    },
  ];

  const fetchAll = () => {
    all()
      .then((plansTeam) => {
        setPlansTeam(plansTeam);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Planos do Clube" subtitle="Lista" />
        <Link to="new">
          <Button
            variant="outlined"
            size="small"
            color="primary"
            startIcon={<i className="uil uil-plus-square" />}
          >
            Adicionar
          </Button>
        </Link>
      </Box>

      <Table columns={columns} id="planTeamId" data={plansTeam} />
    </Box>
  );
}

export default PlansTeam;
