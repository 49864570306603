import { Box } from "@mui/material";

import Header from "../../../components/Header/Header";
import Widget from "../../../components/Widgets/Widget";

function Dashboard() {
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" subtitle="End2End" />
      </Box>
      <div className="main-content">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
            <Widget type="user" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
            <Widget type="order" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6 col-12 mb-3">
            <Widget type="earning" />
          </div>
        </div>
      </div>
    </Box>
  );
}

export default Dashboard;
