//  Create pallete on https://material.io/resources/color/
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useMemo, useState } from "react";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { ThemeModeContext } from "../contexts/theme.mode.context";

interface ColorOverrides {
  black: true;
}

declare module "@mui/material/styles" {
  interface Palette {
    black: Palette["primary"];
  }
  interface PaletteOptions {
    black: PaletteOptions["primary"];
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/Badge" {
  interface BadgePropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides extends ColorOverrides {}
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides extends ColorOverrides {}
}

const theme = (mode: any) => {
  return responsiveFontSizes(
    createTheme({
      typography: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: "inherit",
              fontSize: "1em",
              borderRadius: 100,
              paddingLeft: "2em",
              paddingRight: "2em",
            },
          },
        },
      },
      palette: {
        mode,
        primary: {
          main: "#ff652c",
          light: "#ff975a",
          dark: "#c53200",
          contrastText: "#fff",
        },
        secondary: {
          main: "#00b38f",
          light: "#59e6bf",
          dark: "#008262",
          contrastText: "#fff",
        },
        black: {
          main: "#212121",
          light: "#484848",
          dark: "#000000",
          contrastText: "#fff",
        },
        background: {
          default: mode === "light" ? "#F5F5F5" : "#121212",
        },
      },
    })
  );
};

const ThemesProvider = (props: any) => {
  const localMode = localStorage.getItem(`e2e_backoffice_themeMode`) || "light";
  const [mode, setMode] = useState(localMode);
  const [title, setTitle] = useState("");

  const change = async () => {
    const newMode = mode === "light" ? "dark" : "light";

    setMode(newMode);

    localStorage.setItem(`e2e_backoffice_themeMode`, newMode);
  };

  const value = useMemo(
    () => ({
      mode,
      change,
      title,
      setTitle,
    }),
    [mode, title]
  );

  return (
    <ThemeModeContext.Provider value={value}>
      <ThemeProvider theme={theme(mode)}>{props.children}</ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

export default ThemesProvider;
