import { BackupTable, Feed } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputLabel,
  Tab,
  Tabs,
  TextField,
  useMediaQuery,
  Select as SelectMui,
  MenuItem,
  Button,
  Chip,
  OutlinedInput,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import useButtonLoader from "../../../hooks/useButtonLoader";
import { all } from "../../../services/categories.service";
import { all as getAllClothes } from "../../../services/clothes.service";
import { getById, update } from "../../../services/product.service";
import { ICategory, IOption, IProduct } from "./CreateProduct";
import EditMedia from "./medias/edit/EditMedia";

const EditProduct = () => {
  const { id } = useParams();

  const initialProduct: IProduct = {
    uuid: "",
    name: "",
    categoryId: null,
    description: "",
    sku: "",
    isPromotion: "",
    isBestSeller: "",
    isLaunch: "",
    status: "Active",
    price: null,
    clothes: [],
    medias: [],
  };

  const [editProductButtonElement, setEditProductButtonElement] =
    useButtonLoader("Atualizar produto", "Atualizando...");

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [product, setProduct] = useState(initialProduct);
  const [categories, setCategories] = useState(Array<ICategory>);
  const [optionClothes, setOptionClothes] = useState([] as Array<IOption>);
  const [message, setMessage] = useState("");
  const [tab, setTab] = useState("product");

  const isNonMobile = useMediaQuery("(min-width: 600px)");

  const handleChangeTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleFormSubmit = (values: any) => {
    setEditProductButtonElement(true);

    update(String(id), {
      description: values.description,
      name: values.name,
      price:
        values.price !== product.price ? values.price.toFixed(2) : values.price,
      sku: values.sku,
      categoryId: values.categoryId,
      isPromotion: values.isPromotion === "S",
      isBestSeller: values.isBestSeller === "S",
      isLaunch: values.isLaunch === "S",
      clothes: values.clothes
        ? values.clothes.map((clotheId) => Number(clotheId))
        : [],
    })
      .then(() => {
        setMessage("Produto atualizado com sucesso!");
        setEditProductButtonElement(false);
        setTypeSnackbar("success");
        setOpenSnackbar(true);
      })
      .catch(() => {
        setMessage("Error ao atualizar o produto");
        setEditProductButtonElement(false);
        setTypeSnackbar("error");
        setOpenSnackbar(true);
      });
  };

  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  };

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        <Header title="Editar Produto" subtitle="Editação do produto" />
        <Link
          to="/dashboard/products"
          type="button"
          className="btn btn-outline-warning"
        >
          <i className="uil uil-arrow-left" /> Voltar
        </Link>
      </Box>

      <Tabs value={tab} onChange={handleChangeTab} variant="fullWidth">
        <Tab
          icon={<Feed />}
          iconPosition="start"
          label="Informações"
          value="product"
        />
        <Tab
          icon={<BackupTable />}
          iconPosition="start"
          label="Mídias"
          value="medias"
        />
      </Tabs>

      <div role="tabpanel" id="product" hidden={tab !== "product"}>
        <Formik initialValues={initialProduct} onSubmit={handleFormSubmit}>
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => {
            const findAllClothes = () => {
              getAllClothes()
                .then((clothes) => {
                  const clothesMapped = clothes.map((clothe) => ({
                    value: clothe.clothesAttributesId
                      ? clothe.clothesAttributesId.toString()
                      : "",
                    label: `Modelo: ${clothe.model} - Tamanho: ${clothe.size} - Sexo: ${clothe.genre} - Cor: ${clothe.color}`,
                  }));
                  setOptionClothes(clothesMapped);
                })
                .catch((error) => {
                  console.error(error);
                });
            };

            useEffect(() => {
              if (id) {
                getById(id)
                  .then((response) => {
                    if (response) {
                      if (response.clothes) {
                        const clothesMapped = response.clothes.map(
                          (clothe) => ({
                            value: clothe.clothesId.toString(),
                            label: `Modelo: ${clothe.model} - Tamanho: ${clothe.size} - Sexo: ${clothe.genre} - Cor: ${clothe.color}`,
                          })
                        );

                        setOptionClothes(clothesMapped);
                      }

                      setProduct({
                        ...response,
                        isPromotion: response?.isPromotion ? "S" : "N",
                        isBestSeller: response?.isBestSeller ? "S" : "N",
                        isLaunch: response?.isLaunch ? "S" : "N",
                        clothes:
                          response.clothes &&
                          response.clothes.map((clothe) =>
                            clothe.clothesId.toString()
                          ),
                      });

                      setFieldValue("name", response.name, false);
                      setFieldValue("description", response.description, false);
                      setFieldValue("price", response.price, false);
                      setFieldValue("categoryId", response.categoryId, false);
                      setFieldValue(
                        "isPromotion",
                        response?.isPromotion ? "S" : "N",
                        false
                      );
                      setFieldValue(
                        "isBestSeller",
                        response?.isBestSeller ? "S" : "N",
                        false
                      );
                      setFieldValue(
                        "isLaunch",
                        response?.isLaunch ? "S" : "N",
                        false
                      );
                      setFieldValue(
                        "clothes",
                        response.clothes &&
                          response.clothes.map((clothe) => clothe.clothesId),
                        false
                      );
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
            }, [id]);

            useEffect(() => {
              all().then((categories) => {
                setCategories(categories);
              });
            }, []);

            useEffect(() => {
              if (Number(values.categoryId) === 2) {
                findAllClothes();
              }
            }, [values.categoryId]);

            return (
              <form onSubmit={handleSubmit}>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(6, minmax(0, 1fr))"
                  sx={{
                    "& > div": {
                      gridColumn: isNonMobile ? undefined : "span 6",
                    },
                  }}
                >
                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="Título do produto"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                    defaultValue={values.name}
                    name="name"
                    error={!!touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                    sx={{ gridColumn: "span 3" }}
                  />

                  <FormControl variant="filled" sx={{ gridColumn: "span 3" }}>
                    <InputLabel id="categoryId">Categoria</InputLabel>
                    <SelectMui
                      labelId="categoryId"
                      value={values.categoryId}
                      onChange={handleChange}
                      name="categoryId"
                      error={!!touched.categoryId && !!errors.categoryId}
                    >
                      <MenuItem value="" selected>
                        <em>Selecione uma categoria</em>
                      </MenuItem>
                      {categories.length > 0 ? (
                        categories.map((category) => (
                          <MenuItem
                            key={category.categoryId}
                            value={category.categoryId}
                            selected={values.categoryId === category.categoryId}
                          >
                            {category.name}
                          </MenuItem>
                        ))
                      ) : (
                        <></>
                      )}
                    </SelectMui>
                  </FormControl>

                  <TextField
                    fullWidth
                    variant="filled"
                    type="number"
                    label="Preço"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.price}
                    name="price"
                    error={!!touched.price && !!errors.price}
                    helperText={touched.price && errors.price}
                    sx={{ gridColumn: "span 3" }}
                  />

                  <TextField
                    fullWidth
                    variant="filled"
                    type="text"
                    label="SKU"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.sku}
                    name="sku"
                    error={!!touched.sku && !!errors.sku}
                    helperText={touched.sku && errors.sku}
                    sx={{ gridColumn: "span 3" }}
                  />

                  {Number(values.categoryId) === 2 && (
                    <FormControl variant="filled" sx={{ gridColumn: "span 6" }}>
                      <InputLabel id="clothes">Modelo/Tamanho</InputLabel>
                      <SelectMui
                        id="clothes"
                        multiple
                        value={values.clothes}
                        onChange={(e) => {
                          const clothes: any = e.target.value;

                          setFieldValue(
                            "clothes",
                            removeDuplicates(clothes.map((id) => id.toString()))
                          );
                        }}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((clotheId: number) => {
                              const clothes = optionClothes.find(
                                (clothe) =>
                                  Number(clothe.value) === Number(clotheId)
                              );

                              return (
                                <Chip
                                  key={clothes && clothes.value}
                                  label={clothes && clothes.label}
                                />
                              );
                            })}
                          </Box>
                        )}
                        sx={{ mt: 2 }}
                      >
                        {optionClothes.map((clothe: IOption) => (
                          <MenuItem key={clothe.value} value={clothe.value}>
                            {clothe.label}
                          </MenuItem>
                        ))}
                      </SelectMui>
                    </FormControl>
                  )}

                  <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                    <InputLabel id="isPromotion">Promoção</InputLabel>
                    <SelectMui
                      labelId="isPromotion"
                      value={values.isPromotion}
                      onChange={handleChange}
                      name="isPromotion"
                      error={!!touched.isPromotion && !!errors.isPromotion}
                    >
                      <MenuItem value="" selected>
                        <em>Selecione</em>
                      </MenuItem>
                      <MenuItem key="S" value="S">
                        Sim
                      </MenuItem>
                      <MenuItem key="N" value="N">
                        Não
                      </MenuItem>
                    </SelectMui>
                  </FormControl>

                  <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                    <InputLabel id="isBestSeller">Mais Vendidos</InputLabel>
                    <SelectMui
                      labelId="isBestSeller"
                      value={values.isBestSeller}
                      onChange={handleChange}
                      name="isBestSeller"
                      error={!!touched.isBestSeller && !!errors.isBestSeller}
                    >
                      <MenuItem value="" selected>
                        <em>Selecione</em>
                      </MenuItem>
                      <MenuItem key="S" value="S">
                        Sim
                      </MenuItem>
                      <MenuItem key="N" value="N">
                        Não
                      </MenuItem>
                    </SelectMui>
                  </FormControl>

                  <FormControl variant="filled" sx={{ gridColumn: "span 2" }}>
                    <InputLabel id="isLaunch">Lançamento</InputLabel>
                    <SelectMui
                      labelId="isLaunch"
                      value={values.isLaunch}
                      onChange={handleChange}
                      name="isLaunch"
                      error={!!touched.isLaunch && !!errors.isLaunch}
                    >
                      <MenuItem value="" selected>
                        <em>Selecione</em>
                      </MenuItem>
                      <MenuItem key="S" value="S">
                        Sim
                      </MenuItem>
                      <MenuItem key="N" value="N">
                        Não
                      </MenuItem>
                    </SelectMui>
                  </FormControl>

                  <TextField
                    variant="filled"
                    label="Descrição"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    name="description"
                    minRows={4}
                    multiline
                    error={!!touched.description && !!errors.description}
                    helperText={touched.description && errors.description}
                    sx={{ gridColumn: "span 6" }}
                  />
                </Box>
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    sx={{ mt: 1, mr: 1 }}
                    ref={editProductButtonElement}
                  >
                    Atualizar produto
                  </Button>
                </Box>
              </form>
            );
          }}
        </Formik>
      </div>

      <div role="tabpanel" id="medias" hidden={tab !== "medias"}>
        <div className="mt-1">
          {id && product && (
            <EditMedia medias={product?.medias} productId={id} />
          )}
        </div>
      </div>

      <SnackbarCustom
        key="editProductSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </Box>
  );
};

export default EditProduct;
