import api from "./api";

export async function all() {
  const response = await api.get("/category");

  return response.data;
}

export async function create(data: any) {
  const response = await api.post("/category", data);

  return response.data;
}

export async function getById(id: number) {
  const response = await api.get(`/category/${id}`);

  return response.data;
}

export async function update(id: number, data: any) {
  const response = await api.patch(`/category/${id}`, data);

  return response.data;
}

export async function deleteById(id: number) {
  const response = await api.delete(`category/${id}`);

  return response.data;
}
