import { useNavigate } from "react-router-dom";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import {
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
} from "@mui/material";

function Widget({ type }: any) {
  const navigate = useNavigate();
  let data;

  // temporary
  const amount = 100;
  const diff = 20;

  const go = () => console.log("ok");

  switch (type) {
    case "user":
      data = {
        title: "Usuários",
        isMoney: false,
        link: "Ver todos",
        navigate: "employees",
        icon: <PersonIcon />,
      };
      break;
    case "order":
      data = {
        title: "Pedidos",
        link: "Ver todos",
        navigate: "orders",
        icon: <ShoppingCartIcon />,
      };
      break;
    case "earning":
      data = {
        title: "Receita",
        isMoney: true,
        link: "Ver receita",
        navigate: "",
        icon: <AttachMoneyIcon />,
      };
      break;
    default:
      break;
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {data?.title}
        </Typography>
        <Typography variant="h4" component="div">
          {data?.isMoney && "R$"} {amount}
        </Typography>
      </CardContent>
      <CardActions sx={{ p: 2 }}>
        <Button
          startIcon={data?.icon}
          size="small"
          variant="outlined"
          sx={{ borderRadius: 1, p: "5px 10px" }}
          onClick={() => {
            navigate(data?.navigate);
          }}
        >
          {data?.link}
        </Button>
      </CardActions>
    </Card>
  );
}

export default Widget;
