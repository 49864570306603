import BallotIcon from "@mui/icons-material/Ballot";
import BarChartIcon from "@mui/icons-material/BarChart";
import CategoryIcon from "@mui/icons-material/Category";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import StoreIcon from "@mui/icons-material/Store";

export const SidebarData = [
  {
    title: "Dashboard",
    path: "/dashboard",
    disabled: false,
    icon: <DashboardIcon />,
  },
  {
    title: "Perfil",
    path: "perfil",
    disabled: true,
    icon: <PersonIcon />,
  },
  {
    title: "Usuários",
    path: "users",
    disabled: false,
    icon: <BallotIcon />,
    subMenu: [
      {
        title: "Funcionários",
        path: "employees",
        icon: "",
        disabled: false,
      },
      {
        title: "Sócios/Clientes",
        path: "customers",
        icon: "",
        disabled: false,
      },
    ],
  },
  {
    title: "Integração clube",
    path: "integrations",
    disabled: false,
    icon: <SportsSoccerIcon />,
    subMenu: [
      {
        title: "Desconto",
        path: "integrations/discounts",
        icon: "",
        disabled: false,
      },
      {
        title: "Planos",
        path: "integrations/plans",
        icon: "",
        disabled: false,
      },
    ],
  },
  {
    title: "Categorias",
    path: "categories",
    disabled: false,
    icon: <CategoryIcon />,
    subMenu: [
      {
        title: "Listar",
        path: "categories",
        icon: "",
        disabled: false,
      },
      {
        title: "Cadastrar novo",
        path: "categories/new",
        icon: "",
        disabled: false,
      },
    ],
  },
  {
    title: "Produtos",
    path: "products",
    disabled: false,
    icon: <StoreIcon />,
    iconClosed: "uil uil-apps",
    iconOpened: "uil uil-apps",
    subMenu: [
      {
        title: "Listar",
        path: "products",
        icon: "",
        disabled: false,
      },
      {
        title: "Cadastrar novo",
        path: "products/new",
        icon: "",
        disabled: false,
      },
      {
        title: "Gerenciar camisas",
        path: "clothes",
        icon: "",
        disabled: false,
      },
      {
        title: "Gerenciar planos",
        path: "products/plans",
        icon: "",
        disabled: true,
      },
    ],
  },
  {
    title: "Pedidos",
    path: "orders",
    disabled: false,
    icon: <ReceiptIcon />,
  },
  {
    title: "Estoque",
    path: "stocks",
    disabled: true,
    icon: <Inventory2Icon />,
  },
  {
    title: "Relatórios",
    path: "reports",
    disabled: true,
    icon: <BarChartIcon />,
  },
];
