import { DeleteOutlineOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../../../components/Header/Header";
import SnackbarCustom from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import { all, deleteById } from "../../../services/discounts.service";

function Discounts() {
  const [discounts, setDiscounts] = useState([]);

  const [message, setMessage] = useState("");
  const [typeSnackbar, setTypeSnackbar] = useState("error");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const fetchAll = () => {
    all()
      .then((discounts) => {
        setDiscounts(discounts);
      })
      .catch(() => {
        setOpenSnackbar(true);
        setTypeSnackbar("error");
        setMessage("Não foi possível obter a lista de descontos");
      });
  };

  const deleteDiscounts = (id: number) => {
    deleteById(id)
      .then(() => {
        setOpenSnackbar(true);
        setTypeSnackbar("success");
        setMessage("Deletado com sucesso");
        fetchAll();
      })
      .catch((error) => {
        setOpenSnackbar(true);
        setTypeSnackbar("error");

        if (error.response.status === 400) {
          setMessage(
            "Não foi possível deletar este desconto porque está em uso"
          );

          return;
        }

        setMessage(
          "Não foi possíve deletar este desconto, tente novamente mais tarde!"
        );
      });
  };

  const columns = [
    {
      field: "discountId",
      headerName: "ID",
      flex: 0.25,
    },
    {
      field: "percentual",
      headerName: "Percentual",
      flex: 0.5,
      cellClassName: "discount-column--cell",
    },
    {
      field: "action",
      headerName: "Ação",
      flex: 0.75,
      renderCell: ({ row: { discountId } }) => {
        return (
          <Button
            onClick={() => deleteDiscounts(discountId)}
            variant="outlined"
            size="small"
            color="error"
            startIcon={<DeleteOutlineOutlined />}
          >
            Excluir
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          alignContent="flex-start"
        >
          <Header title="Desconto" subtitle="Lista" />
          <Link to="new">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              startIcon={<i className="uil uil-plus-square" />}
            >
              Adicionar
            </Button>
          </Link>
        </Box>

        <Table columns={columns} data={discounts} id="discountId" />
      </Box>
      <SnackbarCustom
        key="discountListSnackbar"
        message={message}
        openSnackbar={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        severity={typeSnackbar}
      />
    </>
  );
}

export default Discounts;
