import { LogoutOutlined } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme, Box, IconButton, Typography, Avatar } from "@mui/material";
import { useState } from "react";
import {
  Sidebar as SidebarPro,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";

import useAuth from "../../../hooks/useAuth.hook";
import { themeColors } from "../../../theme";
import { SidebarData } from "./SidebarData";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  color,
  paddingLeft,
}) => {
  const { toggleSidebar } = useProSidebar();
  return (
    <MenuItem
      active={selected === title}
      style={{
        color,
        paddingLeft,
      }}
      onClick={() => {
        setSelected(title);
        toggleSidebar();
      }}
      icon={icon}
      component={<Link to={to} />}
    >
      {title}
    </MenuItem>
  );
};

function Sidebar() {
  const theme = useTheme();
  const { Logout, user } = useAuth();
  const colors = themeColors(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  return (
    <Box
      sx={{
        "& .ps-sidebar-root": {
          border: "none !important",
        },
        "& .ps-menuitem-root": {
          padding: "5px 10px 10px 0 !important",
        },
        "& .ps-menu-prefix": {
          backgroundColor: "transparent !important",
        },
        "& .ps-menuitem-root:hover": {
          color: `${colors.orangeAccent[300]} !important`,
        },
        "& .ps-menu-button:hover": {
          color: `${colors.orangeAccent[300]} !important`,
          backgroundColor: "transparent !important",
        },
        "& .ps-menu-button.ps-active": {
          color: `${colors.orangeAccent[700]} !important`,
        },
        "& .ps-submenu-content": {
          background: colors.grey[300],
        },
        "& .ps-submenu-expand-icon": {
          display: isCollapsed ? "none !important" : "block",
        },
      }}
      style={{ display: "flex", height: "100%", minHeight: "500px" }}
    >
      <SidebarPro
        backgroundColor={colors.primary[400]}
        defaultCollapsed={isCollapsed}
        collapsedWidth="70px"
        breakPoint="sm"
        transitionDuration={800}
      >
        <Menu>
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h3" color={colors.grey[100]}>
                  End To End
                </Typography>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  alt="Avatar"
                  src="/assets/imgs/avatars/endtoend.jpeg"
                  sx={{ width: 70, height: 70 }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {user && user.username}
                </Typography>
                <Typography variant="h6" color={colors.orangeAccent[500]}>
                  Administrador
                </Typography>
              </Box>
            </Box>
          )}

          {SidebarData.map((item) => {
            if (!item.disabled) {
              return item?.subMenu ? (
                <SubMenu icon={item.icon} label={item.title} key={item.title}>
                  {item.subMenu.map(
                    (submenu) =>
                      !submenu.disabled && (
                        <Item
                          title={submenu.title}
                          to={submenu.path}
                          icon={submenu.icon}
                          selected={selected}
                          setSelected={setSelected}
                          key={submenu.title}
                          color={colors.orangeAccent[900]}
                          paddingLeft={40}
                        />
                      )
                  )}
                </SubMenu>
              ) : (
                <Item
                  title={item.title}
                  to={item.path}
                  icon={item.icon}
                  selected={selected}
                  setSelected={setSelected}
                  key={item.title}
                  color={colors.grey[100]}
                  paddingLeft={20}
                />
              );
            }

            return null;
          })}

          <MenuItem
            style={{
              color: colors.grey[100],
              paddingLeft: 20,
            }}
            onClick={Logout}
            icon={<LogoutOutlined />}
          >
            Sair
          </MenuItem>
        </Menu>
      </SidebarPro>
    </Box>
  );
}

export default Sidebar;
